export class LanguageStore {
  language;
  currentLanguageStore;
  enFileName;
  deFileName;
  frFileName;
  nlFileName;

  constructor(enFileName, deFileName, frFileName, nlFileName) {
    this.language = null;
    this.enFileName = enFileName;
    this.deFileName = deFileName;
    this.frFileName = frFileName;
    this.nlFileName = nlFileName;
    this.currentLanguageStore = null;
  }

  setLanguage(language) {
    this.loadLanguageFile(`./${language}.json`);
    this.language = language;
  }

  loadLanguageFile(path) {
    // console.log("loading language json file");
    const fileData = require(`${path}`);
    this.currentLanguageStore = fileData;
    // console.log(this.currentLanguageStore);
  }

  t(path) {
    return this.currentLanguageStore.global[path];
  }

  getSlide(index)
  {
    return this.currentLanguageStore.slides[index];
  }

  slideTranslate(path){}
}
