import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../App";
import logo from "../../logo.png";
import "./top-bar.css";


export default function TopBar({ title }) {
  const languageStore = useContext(LanguageContext);

  // console.log(languageStore.language);
  languageStore.setLanguage("de");

  const t = (path) => languageStore.t(path);

  /* const toggleLanguage = () => {
    languageStore.setLanguage("de");
    languageStore.language = "de";
  } */

  useEffect(() => {
    // console.log("store update");
  }, [languageStore.language])

  return (
    <div className="top-bar">
      <div className="left">
        <div className="item">
        </div>
      </div>
      <div className="title" >
        <h1>{title}</h1>
      </div>
      <div className="right">
        <div className="item">
          <a href="/" className="no-link">
            <img className="logo" src={logo}></img>
        </a></div>
      </div>
    </div>
  );
}
