import "./App.css";
import { createContext, useContext, useState } from "react";
import React from "react";
import TopBar from "./components/topBar/topBar";
import { LanguageStore } from "./languages/languageStore";
import Slide from "./components/slide/slide";

let languageStoreObject = new LanguageStore("en.json", "de.json", "fr.json", "nl.json");
export const LanguageContext = createContext(languageStoreObject);

function App() {

  const [state, setState] = useState({background: "1", title: ""});

  const editBackground = (bg) => {
    setState(state => {return {...state, background: bg}});
  }

  const editTitle = (str) => {
    setState(state => {return {...state, title: str}});
  }

  return (
    <div className={`app bg${state.background}`}>
      <div className="app-container">
        <div className="content">
          <LanguageContext.Provider value={languageStoreObject}>
              <div className="top">
                <TopBar title={state.title} />
              </div>
              <div className="center">
              <Slide setTitle={editTitle} setBackground={editBackground} />
              </div>
              <div className="bottom">
              </div>
          </LanguageContext.Provider>
        </div>
      </div>
    </div>
  );
}

export default App;
