import React, { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../../App';
import './slide.css';

export default function Slide({ setBackground, setTitle }) {
    const languageStore = useContext(LanguageContext);

    const [slide, setSlide] = useState({
        type: "",
        title: "",
        text: "",
        answer: "",
        index: 0,
    });

    const [answer, setAnswer] = useState('');

    useEffect(() => {
        const newSlide = languageStore.getSlide(0);
        setSlide({ ...newSlide, index: 0 });
        setTitle(newSlide.title);
    }, [])

    const nextSlide = () => {
        if (correctAnswer(answer)) {
            setSlide(currSlide => {
                let newIndex = currSlide.index + 1;
                newIndex = newIndex > 10 ? 10 : newIndex;
                setBackground(newIndex + 1)
                const newSlide = languageStore.getSlide(newIndex);
                // console.log(newSlide);
                setTitle(newSlide.title);
                return { ...newSlide, index: newIndex }
            });
            setAnswer('');
            // console.log(slide);
        }
    }

    const t = (path) => languageStore.t(path);

    const handleAnswerChange = (e) => {
        setAnswer(e.target.value);
    }

    const simpleString = (str) => {
        return str.toLowerCase().replaceAll(' ', '');
    }

    const compare = (str1, str2) => simpleString(str1) === simpleString(str2);

    const correctAnswer = (str) => {
        return compare(str, slide.answer);
        // return compare(str, slide.answer) || compare(str, "s");
    }

    return (
        <div className="slide">
            <div className={`top ${slide.index !== 0 ? "top-bottom" : ""} ${slide.index !== 10 ? "bottom-bottom" : ""}`} >
                <div className='content'>
                    <p className='text'> <span>{slide.text}</span> </p>
                    {
                        slide.question ?
                            <p className='question'> <span>{slide.question}</span> </p>:
                            null
                    }
                    {
                        slide.index === 0 ?
                        <p className='question small-note'> <span>*Mit Klick auf mein Logo kommst du zur Startseite!</span> </p>:
                        null
                    }
                </div>
            </div>
            <div className="bar">
                {
                    slide.index === 10 ?
                        null :
                        <center>
                            <input className='input' type="text" value={answer} onChange={(e) => { handleAnswerChange(e) }}></input>
                            <button className='button' onClick={() => { nextSlide() }} >{t("SUBMIT")}</button>
                        </center>
                }
            </div>
        </div>
    )
}
